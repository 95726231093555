/**
 * @class FlexibleComboSearchOption
 * @description This class based object handles transforming the data received from API,
 * After receiving the data, it will append its value into listBillItem
 *
 * @property {String | Number} dataId - The ID of the data
 * @property {String} dataName - The name of the data
 * @property {String} dataCode - The code of the data
 * @property {String} dataNameVat - The VAT name of the data (initially empty)
 * @property {Number} discountAmount - The amount of discount for the data
 * @property {Number} discountType - The type of discount for the data
 * @property {String} discountTypeName - The name of the discount type for the data
 * @property {Number} dataType - The type of data (always set to 3)
 * @property {String} imeiCode - The IMEI code of the data (initially empty)
 * @property {Object[]} listAccessoryBonus - The list of accessory bonuses (initially empty)
 * @property {Object[]} listAccessoryPromotion - The list of accessory promotions (initially empty)
 * @property {Number} quantityInStock - The quantity of the data in stock (defaults to 0 if not provided)
 * @property {Number} listedPrice - The listed price of the data (always set to 0)
 * @property {Object[]} listItem - The list of items received from the API
 */
export class FlexibleComboSearchOption {
  /**
   * @param {Object} data
   * @param {String | Number} data.id
   * @param {String} data.name
   * @param {String} data.code
   * @param {Number} data.discountAmount
   * @param {Number} data.discountType
   * @param {String} data.discountTypeName
   * @param {Number} data.inStockQuantity
   * @param {Object[]} data.listItem
   */
  constructor(data = {}) {
    this.productId = data.id;
    this.productName = data.name;
    this.productCode = data.code;
    this.productNameVat = '';
    this.discountAmount = data.discountAmount;
    this.discountType = data.discountType;
    this.discountTypeName = data.discountTypeName;
    this.productType = 3;
    this.imeiCode = '';
    this.listAccessoryBonus = [];
    this.listAccessoryPromotion = [];
    this.quantityInStock = data.inStockQuantity ? data.inStockQuantity : 0;
    this.listedPrice = 0;
    this.listItem = data.listItem;
  }
}
