<template>
  <b-modal
    :ref="refModal"
    @hidden="$emit('hidden')"
    modal-class="searching-modal"
    hide-footer
    :id="refModal"
  >
    <template #modal-title>
      <h5 v-html="modalTitle" class="mb-0"></h5>
    </template>

    <div class="search-nav">
      <h1>Tìm phiếu thu cũ</h1>
      <div class="desc">Chọn phiếu thu cũ cần liên kết</div>
      <div class="search-bar">
        <b-input-group>
          <b-input-group-prepend>
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </b-input-group-prepend>
          <b-form-input
            v-model="stockSlipCode"
            class="search-input"
            size="lg"
            placeholder="Nhập mã phiếu thu cũ"
            @input="onInputChange($event)"
          >
          </b-form-input>
        </b-input-group>
      </div>
    </div>
    <div v-if="listTradeIn.length" class="list-product">
      <template v-for="(item, index) in listTradeIn">
        <div class="product-item" :key="index">
          <div class="head">
            <p>
              <span class="product-item-label">Mã:</span>
              {{ item.stockSlip.code }}
            </p>
            <p>
              <span class="product-item-label">Ngày tạo:</span>
              {{ item.createdAt }}
            </p>
          </div>
          <div class="body">
            <p>
              <span class="product-item-label">Sản phẩm:</span>
              {{ item.product.productName }} -
              <span class="imei-code">IMEI: {{ item.productIMEI }}</span>
            </p>
            <p>
              <span class="product-item-label">Giá:</span>
              {{ item.finalBuyingPrice }}
            </p>
          </div>
          <div class="foot">
            <b-overlay
              :show="isProcessing"
              rounded
              opacity="0.6"
              class="d-inline-block"
            >
              <template #overlay>
                <div class="d-flex align-items-center">
                  <b-spinner variant="light" small type="grow"></b-spinner>
                </div>
              </template>
              <b-button
                variant="primary"
                size="sm"
                @click.once="onSelectTradeIn(item)"
                >Chọn phiếu</b-button
              >
            </b-overlay>
          </div>
        </div>
      </template>
    </div>
    <div class="no-result" v-else>
      <div class="head">
        <h3>Không tìm thấy phiếu thu cũ</h3>
        <p>Vui lòng nhập mã phiếu thu cũ tương ứng với người dùng!</p>
      </div>
      <div class="body">
        <img
          src="media/svg/illustrations/no-data.svg"
          class="w-100 h-200px h-sm-325px"
          alt="no data"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import { EventBus } from '@/core/services/event-bus';
import debounce from 'debounce';
import { TIME_TRIGGER } from '../../../utils/constants';

export default {
  props: {
    customerId: {
      type: [Number, String],
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      isProcessing: false,
      modalTitle: 'Tìm kiếm',
      refModal: 'modal-search-trade-in',
      stockSlipCode: '',
      listTradeIn: [],
    };
  },
  components: {},
  computed: {},
  created() {
    this.fetchCustomerTradeIn();
  },
  watch: {
    customerId: function (val, oldVal) {
      if (val !== oldVal) {
        this.fetchCustomerTradeIn();
      }
    },
  },
  validations: {},
  methods: {
    debounceSearch: debounce(function () {
      this.fetchCustomerTradeIn();
    }, TIME_TRIGGER),
    onSelectTradeIn(data) {
      EventBus.$emit('onSelectTradeIn', data);
      this.$bvModal.hide(this.refModal);
    },
    onInputChange() {
      this.debounceSearch();
    },
    fetchCustomerTradeIn() {
      if (!this.customerId) {
        return;
      }
      const params = {
        customerId: this.customerId,
      };
      if (this.stockSlipCode) {
        params.stockSlipCode = this.stockSlipCode.trim();
      }
      ApiService.query('/tradeIn/search', {
        params: params,
      }).then((response) => {
        const { data, status } = response.data;
        if (status === 1) {
          this.listTradeIn = [...data];
        }
      });
    },
  },
};
</script>

<style scoped>
div /deep/ .modal-dialog {
  max-width: 700px;
  width: 625px;
  height: 625px;
}
div /deep/ .modal-body {
  margin: 0 26px;
}
.product-item-label {
  font-weight: 500;
  color: #3f4254;
  font-size: 1.075rem;
}
.search-nav {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.search-nav h1 {
  color: #181c32;
  font-weight: 500;
}
.search-nav .desc {
  color: #a1a5b7;
  --bs-text-opacity: 1;
  font-weight: 500;
  font-size: 1.15rem;
}
.search-bar {
  position: relative;
  padding-top: 3.25rem;
  margin-bottom: 20px;
}

.search-bar .input-group-text,
.search-input {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: #5e6278;
}
.search-input {
  width: 482px;
  height: 46px;
  transition: color 0.2s ease;
}
.search-input::placeholder {
  color: #b5b5c3;
  font-size: 1.15rem;
  font-weight: 500;
  line-height: 1.5;
}
.list-product {
  margin-bottom: 24px;
  overflow: auto;
  height: 358px;
}
.product-item {
  padding: 1rem;
  border: 1px solid #ecf0f3;
  border-radius: 0.42rem;
  margin-bottom: 10px;
}
.product-item p {
  margin-bottom: 0;
}
.product-item:hover {
  border-color: #009ef7;
}
.product-item .head {
  display: flex;
  justify-content: space-between;
}
.product-item .foot {
  display: flex;
  justify-content: flex-end;
}
.imei-code {
  font-style: italic;
  color: #009ef7;
}
.no-result {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.no-result .head {
  margin-top: 2.5rem;
}
</style>
