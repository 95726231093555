// import { v4 } from 'uuid';
import { BILL_ITEM_TYPE, DISCOUNT_AMOUNT_TYPE } from '@/utils/enum';

/**
 * @class BillItemLogicModal
 * @description This modal handles the logic to transform auto suggest option
 * into an object before sending the request body to the API
 *
 * @property {String} id - A unique identifier for the bill item. It is generated using the v4 function from the uuid library.
 * @property {Number} stt - The serial number of the bill item.
 * @property {String | Number} productId - The ID of the product associated with the bill item.
 * @property {String} name - The name of the product associated with the bill item.
 * @property {String} productNameVat - The name of the product with VAT.
 * @property {String} code - The code of the product associated with the bill item.
 * @property {Number | String} productCategory - The category of the product associated with the bill item.
 * @property {Number} price - The price of the bill item.
 * @property {Number} type - The type of the bill item.
 * @property {Number} quantity - The quantity of the bill item.
 * @property {String} imeiCode - The IMEI code of the bill item.
 * @property {Number} discount - The discount applied to the bill item.
 * @property {Number} quantityInStock - The quantity of the product in stock.
 * @property {Number | String} storeId - The ID of the store associated with the bill item.
 * @property {Object[]} comboItems - The list of products included in a combo.
 * @property {Number | String} billItemType - The type of the bill item (e.g., product, product bonus, product promotion).
 * @property {Boolean} checked - A flag indicating whether the bill item is checked.
 * @property {Object[]} listAccessoryPromotion - The list of accessory promotions associated with the bill item.
 * @property {Number} returnPrice - The return price of the bill item.
 * @property {String | Number} accessoryGroupId - The ID of the accessory group associated with the bill item.
 * @property {String} accessoryGroupCode - The code of the accessory group associated with the bill item.
 * @property {String} note - Additional notes for the bill item.
 * @property {Number} discountType - The type of discount applied to the bill item (e.g., money, percent).
 * @property {Number} discountProgramId - The ID of the discount program associated with the bill item.
 * @property {Boolean} isGetVat - A flag indicating whether the bill item is subject to VAT.
 * @property {Number} sellingPrice - The selling price of the bill item.
 * @property {Number} listedPrice - The listed price of the product associated with the bill item.
 * @property {Number} merchantId - The ID of the merchant associated with the bill item.
 * @property {String} warrantyAddress - The warranty address of the product associated with the bill item.
 * @property {String} warrantyPhone - The warranty phone number of the product associated with the bill item.
 * @property {String} warrantyPhoneNo - The warranty phone number (no formatting) of the product associated with the bill item.
 * @property {String} warrantyDescription - The warranty description of the product associated with the bill item.
 */
export class BillItemLogicModal {
  /**
   * @param {Object} data
   * @param {String | Number} storeId
   * @param {Number} programPrice
   * @param {Number} productType
   * @param {String} imeiCode
   */
  constructor({
    data = {},
    storeId = 0,
    programPrice = 0,
    productType = 1,
    imeiCode = '',
  }) {
    // this.id = v4();
    this.stt = 0;
    this.productId = data.productId;
    this.chassisNumber = data.chassisNumber;
    this.engineNumber = data.engineNumber;
    this.serialNumber = data.serialNumber;
    this.name = data.productName;
    this.productNameVat = data.productNameVat;
    this.code = data.productCode;
    this.productCategory = data.productCategory;
    this.price = programPrice;
    this.type = productType;
    this.quantity = 1;
    this.imeiCode = imeiCode;
    this.discount = 0;
    this.quantityInStock = data.quantityInStock;
    this.storeId = storeId;
    this.comboItems = data.listProductInCombo;
    this.billItemType = BILL_ITEM_TYPE.PRODUCT;
    this.checked = true;
    this.listAccessoryPromotion = data.listAccessoryPromotion;
    this.returnPrice = 0;
    this.accessoryGroupId = '';
    this.accessoryGroupCode = '';
    this.note = '';
    this.discountType = DISCOUNT_AMOUNT_TYPE.PERCENT;
    this.discountProgramId = -1;
    this.isGetVat = false;
    this.sellingPrice = programPrice;
    this.listedPrice = data.listedPrice;
    this.merchantId = data.merchantId || 1;
    // ...installmentProgramModel,
    this.warrantyAddress = data.warrantyAddress || '';
    this.warrantyPhone = data.warrantyPhone || '';
    this.warrantyMonthNo = data.warrantyMonthNo || '';
    this.warrantyDescription = data.warrantyDescription || '';
  }
}
